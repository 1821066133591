<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="1500px">
      <v-card>
        <v-toolbar dark color="#179fdb" flat>
          <v-icon large color="#004e82">mdi-chat-plus</v-icon>
          <v-card-title class="text-h5">platform X - CoPilot</v-card-title>

          <v-row justify="end" style="margin-top: 0.4em">
            <span
              style="margin-right: 0.4em; margin-top: 0.05em; display: inline-block"
              :style="switchOn ? 'color:#e6e6e6;' : 'color:white;'"
              >Schnell</span
            >
            <v-switch v-model="switchOn"></v-switch>
            <span
              style="margin-left: 0.2em; margin-top: 0.02em; display: inline-block"
              :style="switchOn ? 'font-weight: bold;' : 'color:#e6e6e6;'"
              >Präzise</span
            >
          </v-row>

          <v-col cols="2" class="text-right" align-self="center">
            <v-btn icon dark @click.stop="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text style="height: 800px">
          <div id="thisElement" style="scroll-margin-bottom: 100px">
            <v-container class="fill-height">
              <v-row class="fill-height pb-14" align="end">
                <v-col>
                  <div
                    v-for="(item, index) in chat"
                    :key="index"
                    :class="[
                      'd-flex flex-row align-center my-2',
                      item.from == 'user' ? 'justify-end' : null
                    ]"
                  >
                    <div
                      v-if="item.from == 'user'"
                      class="blue--text mr-3"
                      style="background-color:azure;"
                    >
                      {{ item.msg }}
                    </div>
                    <v-avatar v-if="item.from == 'user'" color="#0a6aa8" size="36">
                      <span class="white--text">N</span>
                    </v-avatar>
                    <v-avatar v-if="item.from == 'sushant'" color="#004e82" size="36">
                      <span class="white--text">B</span>
                    </v-avatar>
                    <!-- like button -->
                    <div v-if="item.from != 'user' && !item.isLoading" class="black--text ml-3">
                      <div v-for="(entry, index) in item.msg" :key="index">
                        <!-- <pre style="white-space: pre-wrap;">{{ entry }}</pre> -->
                        <!-- <pre style="white-space: pre-wrap;"  v-html="entry"></pre> -->
                        <div v-html="entry"></div>
                      </div>
                      <div v-if="item.sources.length > 0">
                        Mögliche Quellen:
                        <span
                          v-for="(entry, index) in item.sources"
                          :key="`${index}-${Date.now()}`"
                        >
                          <a :href="entry[0]" target="_blank">Quelle {{ index + 1 }}</a
                          >&nbsp;&nbsp;
                        </span>
                      </div>
                      <div v-if="index !== 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="item.likeClicked"
                              v-bind="attrs"
                              v-on="on"
                              tile
                              icon
                              color="blue lighten-2"
                              @click.stop="onLikeClicked(index, 1)"
                              ><v-icon> mdi-thumb-up-outline </v-icon></v-btn
                            >
                          </template>

                          <span
                            >Klicke hier, um den Suchverlauf zu speichern und die Leistung unserer
                            Suchergebnisse zu verbessern.</span
                          >
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="item.likeClicked"
                              v-bind="attrs"
                              v-on="on"
                              tile
                              icon
                              color="red lighten-2"
                              @click.stop="onLikeClicked(index, 0)"
                              ><v-icon> mdi-thumb-down-outline </v-icon></v-btn
                            >
                          </template>

                          <span
                            >Klicke hier, um den Suchverlauf zu speichern und die Leistung unserer
                            Suchergebnisse zu verbessern.</span
                          >
                        </v-tooltip>
                      </div>
                    </div>
                    <!-- loading circle -->
                    <div v-if="item.from != 'user' && item.isLoading" class="black--text ml-5">
                      <v-row>
                        <v-progress-circular indeterminate color="#004e82"></v-progress-circular
                      ></v-row>
                      <v-row>
                        <div v-for="(entry, i) in testContent" :key="i">
                          <!-- <pre style="white-space: pre-wrap;">{{ entry }}</pre> -->
                          <div v-html="entry"></div>
                        </div>
                        <!-- <div class="black--text ml-3">{{ testContent }}</div> -->
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container class="ma-0 pa-0" fluid>
            <v-row no-gutters>
              <v-col>
                <div class="d-flex flex-row align-center">
                  <v-text-field
                    v-model="msg"
                    placeholder="Stelle eine Frage"
                    @keypress.enter="onSendClick"
                  ></v-text-field>
                  <v-btn icon class="ml-4" @click="onSendClick"><v-icon>mdi-send</v-icon></v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import axios from 'axios';
import { mapGetters } from 'vuex';
import { createChatBotQuestion } from '@/graphql/mutations';
import { API } from 'aws-amplify';
import { marked } from 'marked';

export default {
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      jwt: '',
      switchOn: true,
      chat: [],
      msg: null,
      tokenBuffer: '',
      testContent: '',
      input: {
        baseUrl: 'https://oagrtdcsnijsy77426vcpjmlqq0zsvef.lambda-url.eu-central-1.on.aws/', //prod new
        // baseUrl: 'https://7xq4lnug2frc3dw74pqju32gce0lfcxw.lambda-url.eu-central-1.on.aws/', //local
        // baseUrl: 'https://data-ai.api.dev.data.pl-x.cloud/',//prod
        modelName: 'gpt-3.5-turbo-0125',
        k: 7
      },
      data: {
        answer_length: 0,
        chat_model: '',
        helpful: 0,
        question: '',
        timestamp: 0,
        user: ''
      },
      likedButtonClicked: false,
      test: '123'
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    ...mapGetters(['jwtToken', 'kurzel'])
  },
  // watch: {
  //   'this.tokenBuffer'() {
  //     this.testContent = ['this.tokenBuffer'];

  //     console.log('debug111: ', this.testContent);
  //   }
  // },
  created() {
    this.chat.push({
      from: 'sushant',
      msg: ['Hallo und herzlich willkommen! Kann ich Dir behilflich sein?'],
      sources: [],
      isLoading: false,
      tokenBuffer: '',
      likeClicked: false
    });
  },
  methods: {
    async onLikeClicked(index, like) {
      this.data.timestamp = Math.floor(Date.now() / 1000);
      const lastAnswer = [...this.chat].pop();
      const lastQuestion = this.chat[this.chat.length - 2].msg;
      const input = {
        answer_length: JSON.stringify(lastAnswer.msg).length,
        chat_model: this.input.modelName,
        helpful: like,
        question: lastQuestion,
        timestamp: Math.floor(Date.now() / 1000),
        user: this.kurzel
      };
      this.chat[index].likeClicked = true;
      console.log('like me', input);
      console.log('index: ' + index);
      await API.graphql({
        query: createChatBotQuestion,
        variables: { input: input }
      });
    },
    onSendClick() {
      this.likedButtonClicked = false;
      console.log('debug input: ' + this.msg);
      this.chat.push({
        from: 'user',
        msg: this.msg
      });
      this.chat.push({
        from: 'sushant',
        msg: [''],
        sources: [],
        isLoading: true
      });
      this.testContent = '';
      this.addReply();
      this.moveScrollBar();

      this.msg = null;
    },
    moveScrollBar() {
      let element = document.getElementById('thisElement');
      element.scrollIntoView(false, { behavior: 'smooth', block: 'end', inline: 'end' });
    },
    async addReply() {
      //marked setup
      // const renderer = new marked.Renderer();
      // const linkRenderer = renderer.link;
      // renderer.link = (href, title, text) => {
      //   const localLink = href.startsWith(`${location.protocol}//${location.hostname}`);
      //   const html = linkRenderer.call(renderer, href, title, text);
      //   return localLink
      //     ? html
      //     : html.replace(/^<a /, `<a target="_blank" rel="noreferrer noopener nofollow" `);
      // };
      // marked.setOptions({
      //   breaks: true, // Equivalent to 'nl2br' extension
      //   tables: true, // Support for GFM tables
      //   renderer: renderer
      // });
      if (!this.switchOn) {
        this.input.modelName = 'gpt-3.5-turbo-0125';
        this.input.k = 7;
        console.log(this.input.modelName + ', ' + this.input.k);
      } else {
        this.input.modelName = 'gpt-4o-2024-05-13';
        this.input.k = 12;
        console.log(this.input.modelName + ', ' + this.input.k);
      }
      const url =
        this.input.baseUrl +
        'answer?question=' +
        encodeURIComponent(this.msg.trim()) +
        '&k=' +
        this.input.k +
        '&model=' +
        encodeURIComponent(this.input.modelName) +
        '&stream=true';
      console.log('debug10: ' + new Date(Date.now()).toISOString());

      const response = await fetch(url, {
        method: 'GET',
        headers: { Authorization: this.jwtToken }
      });
      console.log('debug11: ' + new Date(Date.now()).toISOString());
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let partialBuffer = '';
      this.tokenBuffer = '';
      let sources = [];

      let checker = true;
      while (checker) {
        try {
          const { done, value } = await reader.read();
          if (done) {
            // let finalResult = this.tokenBuffer.replaceAll('**', '').split('\n');
            let finalResult = marked.parse(this.tokenBuffer);
            this.chat.pop();

            if (Object.keys(finalResult).length > 1 && finalResult[0]) {
              console.log('debug1: ' + Object.keys(finalResult).length);
              this.chat.push({
                from: 'sushant',
                msg: [finalResult],
                sources: sources,
                isLoading: false,
                likeClicked: false
              });

              console.log('Stream complete');
            } else if (Object.keys(finalResult).length === 1 && finalResult) {
              this.chat.push({
                from: 'sushant',
                msg: [finalResult],
                sources: [],
                isLoading: false,
                likeClicked: false
              });
              console.log(finalResult);
            } else {
              // console.log('failed: '+Object.keys(finalResult))
              this.chat.push({
                from: 'sushant',
                msg: ['Es tut mir leid, etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.'],
                sources: [],
                isLoading: false,
                likeClicked: false
              });
            }
            checker = false;

            break;
          }
          partialBuffer = decoder.decode(value, { stream: true });
          // console.log('debug 233: '+partialBuffer)
          // As per API contract we use the null character as a delimiter
          const lines = partialBuffer.split('\0');
          // console.log('debug234: '+lines)

          lines.forEach(line => {
            if (line.startsWith('sources:')) {
              // initializeChatMessage(conversationId, question);
              sources = JSON.parse(line.slice(8));
              console.log(sources);
              // appendSourceLinks(conversationId, sources);
              partialBuffer = '';
            } else if (line.startsWith('data:')) {
              const tokens = line.slice(5);
              this.tokenBuffer += tokens;
              // this.testContent = this.tokenBuffer.replaceAll('**', '').split('\n');
              // handle streaming token to html
              const newMarkdown = this.tokenBuffer;
              if (newMarkdown.includes('\n')) {
                let lastIndex = newMarkdown.lastIndexOf('\n');
                // Adjust lastIndex if there are consecutive newlines
                while (lastIndex > 0 && newMarkdown[lastIndex - 1] === '\n') {
                  lastIndex--;
                }
                // Split the string into two parts
                const part1 = newMarkdown.substring(0, lastIndex);
                const part2 = newMarkdown.substring(lastIndex);
                this.testContent = [marked.parse(part1) + part2];

              }else{
                this.testContent = [this.tokenBuffer];
              }
              this.moveScrollBar();
              // console.log('debug22: ' + new Date(Date.now()).toISOString() + this.tokenBuffer);
            }
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
};
</script>
