/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNboRecBacktestResult = /* GraphQL */ `
  query GetNboRecBacktestResult($id: ID!) {
    getNboRecBacktestResult(id: $id) {
      id
      purchase_train
      bp_id
      recommendations
      purchase_test
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNboRecBacktestResults = /* GraphQL */ `
  query ListNboRecBacktestResults(
    $filter: ModelNboRecBacktestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNboRecBacktestResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        purchase_train
        bp_id
        recommendations
        purchase_test
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNboSurveyAnswers = /* GraphQL */ `
  query GetNboSurveyAnswers($id: ID!) {
    getNboSurveyAnswers(id: $id) {
      id
      result
      nboRecBacktestResultID
      createdBy
      nboRecBacktestResult {
        id
        purchase_train
        bp_id
        recommendations
        purchase_test
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      nboSurveyAnswersNboRecBacktestResultId
      __typename
    }
  }
`;
export const listNboSurveyAnswers = /* GraphQL */ `
  query ListNboSurveyAnswers(
    $filter: ModelNboSurveyAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNboSurveyAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        result
        nboRecBacktestResultID
        createdBy
        createdAt
        updatedAt
        nboSurveyAnswersNboRecBacktestResultId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMarketingTypeGroup = /* GraphQL */ `
  query GetMarketingTypeGroup($id: ID!) {
    getMarketingTypeGroup(id: $id) {
      id
      code
      name
      description
      marketingTypes {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMarketingTypeGroups = /* GraphQL */ `
  query ListMarketingTypeGroups(
    $filter: ModelMarketingTypeGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketingTypeGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArticleType = /* GraphQL */ `
  query GetArticleType($id: ID!) {
    getArticleType(id: $id) {
      id
      code
      name
      description
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArticleTypes = /* GraphQL */ `
  query ListArticleTypes(
    $filter: ModelArticleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOverwriteContractPayedRatio = /* GraphQL */ `
  query GetOverwriteContractPayedRatio($id: ID!) {
    getOverwriteContractPayedRatio(id: $id) {
      id
      contractPayedRatioTheshold
      minMax
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      conditionID
      condition {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      reason
      approvedControllingBy
      validFrom
      validTill
      approvedPublisherBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOverwriteContractPayedRatios = /* GraphQL */ `
  query ListOverwriteContractPayedRatios(
    $filter: ModelOverwriteContractPayedRatioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverwriteContractPayedRatios(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractPayedRatioTheshold
        minMax
        articleID
        marketingTypeID
        conditionID
        createdBy
        reason
        approvedControllingBy
        validFrom
        validTill
        approvedPublisherBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCondition = /* GraphQL */ `
  query GetCondition($id: ID!) {
    getCondition(id: $id) {
      id
      code
      name
      description
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConditions = /* GraphQL */ `
  query ListConditions(
    $filter: ModelConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOverwriteMonthInAboNull = /* GraphQL */ `
  query GetOverwriteMonthInAboNull($id: ID!) {
    getOverwriteMonthInAboNull(id: $id) {
      id
      minMax
      monthThreshold
      conditionID
      condition {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      validFrom
      validTill
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      reason
      approvedControllingBy
      approvedPublisherBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOverwriteMonthInAboNulls = /* GraphQL */ `
  query ListOverwriteMonthInAboNulls(
    $filter: ModelOverwriteMonthInAboNullFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverwriteMonthInAboNulls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        minMax
        monthThreshold
        conditionID
        articleID
        createdBy
        validFrom
        validTill
        marketingTypeID
        reason
        approvedControllingBy
        approvedPublisherBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrderCompensation = /* GraphQL */ `
  query GetOrderCompensation($id: ID!) {
    getOrderCompensation(id: $id) {
      id
      price
      validFrom
      description
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      approvedConsultingBy
      approvedConsultingOn
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrderCompensations = /* GraphQL */ `
  query ListOrderCompensations(
    $filter: ModelOrderCompensationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderCompensations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        price
        validFrom
        description
        marketingTypeID
        articleID
        approvedConsultingBy
        approvedConsultingOn
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOverwriteArticlePrice = /* GraphQL */ `
  query GetOverwriteArticlePrice($id: ID!) {
    getOverwriteArticlePrice(id: $id) {
      id
      priceThreshold
      validFrom
      validTill
      createdBy
      description
      minMax
      reason
      type
      approvedControllingBy
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      approvedPublisherBy
      approvedDataBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOverwriteArticlePrices = /* GraphQL */ `
  query ListOverwriteArticlePrices(
    $filter: ModelOverwriteArticlePriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverwriteArticlePrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        priceThreshold
        validFrom
        validTill
        createdBy
        description
        minMax
        reason
        type
        approvedControllingBy
        articleID
        approvedPublisherBy
        approvedDataBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMarketingType = /* GraphQL */ `
  query GetMarketingType($id: ID!) {
    getMarketingType(id: $id) {
      id
      code
      description
      marketingTypeGroup {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      marketingTypeGroupID
      isOrderConsumption
      isNGW
      isPsb
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMarketingTypes = /* GraphQL */ `
  query ListMarketingTypes(
    $filter: ModelMarketingTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketingTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      code
      title
      articleType {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleTypeID
      number
      isActive
      mandatorCode
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPriceSegmentOverwrite = /* GraphQL */ `
  query GetPriceSegmentOverwrite($id: ID!) {
    getPriceSegmentOverwrite(id: $id) {
      id
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      priceSegment
      reason
      validFrom
      validTill
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPriceSegmentOverwrites = /* GraphQL */ `
  query ListPriceSegmentOverwrites(
    $filter: ModelPriceSegmentOverwriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriceSegmentOverwrites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleID
        priceSegment
        reason
        validFrom
        validTill
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAffiliateHardMapping = /* GraphQL */ `
  query GetAffiliateHardMapping($id: ID!) {
    getAffiliateHardMapping(id: $id) {
      id
      affiliateSource
      affiliateTarget
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAffiliateHardMappings = /* GraphQL */ `
  query ListAffiliateHardMappings(
    $filter: ModelAffiliateHardMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffiliateHardMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliateSource
        affiliateTarget
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCooperationCost = /* GraphQL */ `
  query GetCooperationCost($id: ID!) {
    getCooperationCost(id: $id) {
      id
      affiliate
      costType
      cost
      totalCost
      fromDate
      untilDate
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCooperationCosts = /* GraphQL */ `
  query ListCooperationCosts(
    $filter: ModelCooperationCostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCooperationCosts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliate
        costType
        cost
        totalCost
        fromDate
        untilDate
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNetworkType = /* GraphQL */ `
  query GetNetworkType($id: ID!) {
    getNetworkType(id: $id) {
      id
      cd
      description
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNetworkTypes = /* GraphQL */ `
  query ListNetworkTypes(
    $filter: ModelNetworkTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNetworkTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cd
        description
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAffiliateType = /* GraphQL */ `
  query GetAffiliateType($id: ID!) {
    getAffiliateType(id: $id) {
      id
      cd
      description
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAffiliateTypes = /* GraphQL */ `
  query ListAffiliateTypes(
    $filter: ModelAffiliateTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffiliateTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cd
        description
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAgency = /* GraphQL */ `
  query GetAgency($id: ID!) {
    getAgency(id: $id) {
      id
      cd
      name
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAgencies = /* GraphQL */ `
  query ListAgencies(
    $filter: ModelAgencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgencies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAgencyCost = /* GraphQL */ `
  query GetAgencyCost($id: ID!) {
    getAgencyCost(id: $id) {
      id
      agency {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      network {
        id
        cd
        name
        channelCd
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      affiliateType {
        id
        cd
        description
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      targetGroup {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      subpublisher {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      newsletter {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        newsletterTargetGroupId
        __typename
      }
      fromDate
      freeText
      totalCost
      createdBy
      sortKey
      createdAt
      updatedAt
      agencyCostAgencyId
      agencyCostNetworkId
      agencyCostAffiliateTypeId
      agencyCostTargetGroupId
      agencyCostSubpublisherId
      agencyCostNewsletterId
      __typename
    }
  }
`;
export const listAgencyCosts = /* GraphQL */ `
  query ListAgencyCosts(
    $filter: ModelAgencyCostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgencyCosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fromDate
        freeText
        totalCost
        createdBy
        sortKey
        createdAt
        updatedAt
        agencyCostAgencyId
        agencyCostNetworkId
        agencyCostAffiliateTypeId
        agencyCostTargetGroupId
        agencyCostSubpublisherId
        agencyCostNewsletterId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNetwork = /* GraphQL */ `
  query GetNetwork($id: ID!) {
    getNetwork(id: $id) {
      id
      cd
      name
      channelCd
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNetworks = /* GraphQL */ `
  query ListNetworks(
    $filter: ModelNetworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNetworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cd
        name
        channelCd
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTargetGroup = /* GraphQL */ `
  query GetTargetGroup($id: ID!) {
    getTargetGroup(id: $id) {
      id
      name
      subpublisher {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      targetGroupSubpublisherId
      __typename
    }
  }
`;
export const listTargetGroups = /* GraphQL */ `
  query ListTargetGroups(
    $filter: ModelTargetGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargetGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsletter = /* GraphQL */ `
  query GetNewsletter($id: ID!) {
    getNewsletter(id: $id) {
      id
      cd
      name
      targetGroup {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      newsletterTargetGroupId
      __typename
    }
  }
`;
export const listNewsletters = /* GraphQL */ `
  query ListNewsletters(
    $filter: ModelNewsletterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        newsletterTargetGroupId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubpublisher = /* GraphQL */ `
  query GetSubpublisher($id: ID!) {
    getSubpublisher(id: $id) {
      id
      cd
      name
      planningDivision {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      subpublisherPlanningDivisionId
      __typename
    }
  }
`;
export const listSubpublishers = /* GraphQL */ `
  query ListSubpublishers(
    $filter: ModelSubpublisherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubpublishers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlanningDivision = /* GraphQL */ `
  query GetPlanningDivision($id: ID!) {
    getPlanningDivision(id: $id) {
      id
      cd
      name
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlanningDivisions = /* GraphQL */ `
  query ListPlanningDivisions(
    $filter: ModelPlanningDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanningDivisions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOverwriteArticle = /* GraphQL */ `
  query GetOverwriteArticle($id: ID!) {
    getOverwriteArticle(id: $id) {
      active
      article_code
      controller
      controller_confirmed
      createdBy
      manager
      manager_confirmed
      mandator_cd
      marketing_type_cd
      article_displayname
      ngw
      ngw_based_costs_percentage
      ngw_based_costs_percentage_overwritten
      ngw_cost
      ngw_cost_original
      ngw_cost_overwritten_value
      ngw_costs_overwritten
      ngw_factor_overwritten_value
      reason
      usrmail
      subpublisher_displayname
      timestamp
      valid_from
      valid_until
      delete_marked
      expired
      handled
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOverwriteArticles = /* GraphQL */ `
  query ListOverwriteArticles(
    $filter: ModelOverwriteArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverwriteArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        active
        article_code
        controller
        controller_confirmed
        createdBy
        manager
        manager_confirmed
        mandator_cd
        marketing_type_cd
        article_displayname
        ngw
        ngw_based_costs_percentage
        ngw_based_costs_percentage_overwritten
        ngw_cost
        ngw_cost_original
        ngw_cost_overwritten_value
        ngw_costs_overwritten
        ngw_factor_overwritten_value
        reason
        usrmail
        subpublisher_displayname
        timestamp
        valid_from
        valid_until
        delete_marked
        expired
        handled
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOverwriteWKZ = /* GraphQL */ `
  query GetOverwriteWKZ($id: ID!) {
    getOverwriteWKZ(id: $id) {
      active
      controller
      controller_confirmed
      createdBy
      delete_marked
      manager
      manager_confirmed
      mandator_cd
      marketing_cd
      ngw
      ngw_based_costs_percentage
      ngw_based_costs_percentage_overwritten
      ngw_cost
      ngw_cost_original
      ngw_cost_overwritten_value
      ngw_costs_overwritten
      ngw_factor_overwritten_value
      usrmail
      reason
      subpublisher_displayname
      timestamp
      valid_from
      valid_until
      expired
      handled
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOverwriteWKZS = /* GraphQL */ `
  query ListOverwriteWKZS(
    $filter: ModelOverwriteWKZFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverwriteWKZS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        active
        controller
        controller_confirmed
        createdBy
        delete_marked
        manager
        manager_confirmed
        mandator_cd
        marketing_cd
        ngw
        ngw_based_costs_percentage
        ngw_based_costs_percentage_overwritten
        ngw_cost
        ngw_cost_original
        ngw_cost_overwritten_value
        ngw_costs_overwritten
        ngw_factor_overwritten_value
        usrmail
        reason
        subpublisher_displayname
        timestamp
        valid_from
        valid_until
        expired
        handled
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOverwriteNGW = /* GraphQL */ `
  query GetOverwriteNGW($id: ID!) {
    getOverwriteNGW(id: $id) {
      active
      anfo_month
      article_cd
      article_code
      article_displayname
      cas_00_r03
      cas_36_r03
      condition_group
      controller
      controller_confirmed
      createdBy
      delete_marked
      expired
      handled
      mandator_cd
      manager
      manager_confirmed
      marketing_type_cd
      marketing_sub_type_cd
      ov_cp
      ov_cp_minMax
      ov_cp_contractPayedRatioThreshold
      ov_m
      ov_m_minMax
      ov_m_monthThreshold
      reason
      subpublisher_displayname
      timestamp
      usrmail
      valid_from
      valid_until
      ngwValue
      newNGW
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOverwriteNGWS = /* GraphQL */ `
  query ListOverwriteNGWS(
    $filter: ModelOverwriteNGWFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverwriteNGWS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        active
        anfo_month
        article_cd
        article_code
        article_displayname
        cas_00_r03
        cas_36_r03
        condition_group
        controller
        controller_confirmed
        createdBy
        delete_marked
        expired
        handled
        mandator_cd
        manager
        manager_confirmed
        marketing_type_cd
        marketing_sub_type_cd
        ov_cp
        ov_cp_minMax
        ov_cp_contractPayedRatioThreshold
        ov_m
        ov_m_minMax
        ov_m_monthThreshold
        reason
        subpublisher_displayname
        timestamp
        usrmail
        valid_from
        valid_until
        ngwValue
        newNGW
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatBotQuestion = /* GraphQL */ `
  query GetChatBotQuestion($id: ID!) {
    getChatBotQuestion(id: $id) {
      answer_length
      chat_model
      helpful
      question
      timestamp
      user
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChatBotQuestions = /* GraphQL */ `
  query ListChatBotQuestions(
    $filter: ModelChatBotQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatBotQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        answer_length
        chat_model
        helpful
        question
        timestamp
        user
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const affiliateHardMappingByCreatedDate = /* GraphQL */ `
  query AffiliateHardMappingByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAffiliateHardMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    affiliateHardMappingByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliateSource
        affiliateTarget
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const cooperationCostByCreatedDate = /* GraphQL */ `
  query CooperationCostByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCooperationCostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cooperationCostByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        affiliate
        costType
        cost
        totalCost
        fromDate
        untilDate
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const networkTypeByCreatedDate = /* GraphQL */ `
  query NetworkTypeByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNetworkTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    networkTypeByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cd
        description
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const affiliateTypeByCreatedDate = /* GraphQL */ `
  query AffiliateTypeByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAffiliateTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    affiliateTypeByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cd
        description
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const agencyByCreatedDate = /* GraphQL */ `
  query AgencyByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agencyByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const agencyCostByCreatedDate = /* GraphQL */ `
  query AgencyCostByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgencyCostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agencyCostByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fromDate
        freeText
        totalCost
        createdBy
        sortKey
        createdAt
        updatedAt
        agencyCostAgencyId
        agencyCostNetworkId
        agencyCostAffiliateTypeId
        agencyCostTargetGroupId
        agencyCostSubpublisherId
        agencyCostNewsletterId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const networkByCreatedDate = /* GraphQL */ `
  query NetworkByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNetworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    networkByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cd
        name
        channelCd
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const targetGroupByCreatedDate = /* GraphQL */ `
  query TargetGroupByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTargetGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    targetGroupByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const newsletterByCreatedDate = /* GraphQL */ `
  query NewsletterByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewsletterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    newsletterByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        newsletterTargetGroupId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subpublisherByCreatedDate = /* GraphQL */ `
  query SubpublisherByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubpublisherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subpublisherByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const planningDivisionByCreatedDate = /* GraphQL */ `
  query PlanningDivisionByCreatedDate(
    $sortKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlanningDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    planningDivisionByCreatedDate(
      sortKey: $sortKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
